<template>
  <div style="width: 100%;">
    <base-test-answer
      v-if="testId"
      style="padding-top: 5rem"
      :testId="parseInt(testId)"
    ></base-test-answer>
    <!-- <base-survey-answer
      style="padding-top: 5rem"
      :surveyId="12"
    ></base-survey-answer> -->
    <!-- <base-survey-answer
      style="padding-top: 5rem"
      :surveyId="13"
    ></base-survey-answer> -->
    <!-- <base-survey-answer
      style="padding-top: 5rem"
      :surveyId="14"
      :teachingDareId="3"
    ></base-survey-answer> -->
  </div>
</template>
<script>
import BaseTestAnswer from "../../components/form/BaseTestAnswer.vue";
export default {
  components: {
    BaseTestAnswer,
  },

  computed: {
    testId() {
      console.log(this.$route.query.testId);
      return this.$route.query.testId;
    },
  },
};
</script>