var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.survey && !_vm.surveyIsSent)?_c('div',[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-lg-10 text-center mb-4"},[_c('h3',{staticStyle:{"font-weight":"600"}},[_vm._v("ทำข้อสอบ")]),_c('h3',{staticStyle:{"font-weight":"600","line-height":"1.5"}},[_vm._v(" ชื่อแบบทดสอบ : "+_vm._s(_vm.survey.test_name)+" ")]),(_vm.survey.description)?_c('h3',{staticStyle:{"font-weight":"400"}},[_vm._v(" "+_vm._s(_vm.survey.description)+" ")]):_vm._e(),_c('hr')])]),_c('div',{staticClass:"row justify-content-center mb-5"},[(_vm.loadSurvey)?_c('div',{staticClass:"col-12 d-flex justify-content-center"},[_vm._m(0)]):_vm._e()]),(_vm.questions)?_c('div',{staticClass:"row justify-content-center"},_vm._l((_vm.questions),function(question){return _c('div',{key:("question_" + (question.id)),staticClass:"col-12 col-lg-10"},[(question && question.survey_topic_no)?_c('div',[_c('h5',{staticClass:"mb-3"},[_vm._v(" "+_vm._s(question.survey_topic_no)+". "+_vm._s(question.survey_topic_title)+" ")]),_vm._l((question.questions),function(questionTopic){return _c('div',{key:("question_" + (questionTopic.id))},[(
              questionTopic.question_type == 'choice' &&
              questionTopic.test_question_choices &&
              questionTopic.test_question_choices.length > 0
            )?_c('div',{},[_c('h5',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(question.survey_topic_no)+"."+_vm._s(questionTopic.question_no)+" "+_vm._s(questionTopic.question_title)+" ")]),_c('b-form-group',{staticClass:"ml-5"},_vm._l((questionTopic.test_question_choices),function(choice){return _c('b-form-radio',{key:("question_choice_" + (choice.id)),staticClass:"my-3 sub-survey-topic",attrs:{"name":("question_choice_" + (choice.id)),"value":choice.id},model:{value:(questionTopic.question_answers),callback:function ($$v) {_vm.$set(questionTopic, "question_answers", $$v)},expression:"questionTopic.question_answers"}},[_c('h5',[_vm._v(" "+_vm._s(choice.question_choice_title)+" ")])])}),1)],1):_vm._e(),(questionTopic.question_type == 'text')?_c('div',{},[_c('h5',{staticClass:"ml-4"},[_vm._v(" "+_vm._s(question.survey_topic_no)+"."+_vm._s(questionTopic.question_no)+" "+_vm._s(questionTopic.question_title)+" ")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(questionTopic.question_answers),expression:"questionTopic.question_answers"}],staticClass:"form-control my-3 ml-5",staticStyle:{"width":"-webkit-fill-available"},attrs:{"id":("question_text_" + (questionTopic.id)),"rows":"3","placeholder":"ตอบ"},domProps:{"value":(questionTopic.question_answers)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(questionTopic, "question_answers", $event.target.value)}}})]):_vm._e(),(questionTopic.question_type == 'rating')?_c('div',{staticClass:"mb-3"},[_c('h5',{staticClass:"ml-4"},[_vm._v(" "+_vm._s(question.survey_topic_no)+"."+_vm._s(questionTopic.question_no)+" "+_vm._s(questionTopic.question_title)+" ")]),_c('vue-slide-bar',{attrs:{"data":_vm.slider.data,"range":_vm.slider.range,"labelStyles":{ color: '#4a4a4a', backgroundColor: '#4a4a4a' },"processStyle":{
                backgroundColor: _vm.genColors(questionTopic.question_answers),
              },"tooltipStyles":{
                backgroundColor: _vm.genColors(questionTopic.question_answers),
                borderColor: _vm.genColors(questionTopic.question_answers),
              }},model:{value:(questionTopic.question_answers),callback:function ($$v) {_vm.$set(questionTopic, "question_answers", $$v)},expression:"questionTopic.question_answers"}})],1):_vm._e()])})],2):_c('div',[_c('h5',{},[_vm._v(" "+_vm._s(question.question_no)+". "+_vm._s(question.question_title)+" ")]),(
            question.question_type == 'choice' &&
            question.test_question_choices &&
            question.test_question_choices.length > 0
          )?_c('div',{},[_c('b-form-group',{staticClass:"ml-4"},_vm._l((question.test_question_choices),function(choice){return _c('b-form-radio',{key:("question_choice_" + (choice.id)),staticClass:"my-3",attrs:{"name":("question_choice_" + (choice.id)),"value":choice.id},model:{value:(question.question_answers),callback:function ($$v) {_vm.$set(question, "question_answers", $$v)},expression:"question.question_answers"}},[_c('h5',[_vm._v(" "+_vm._s(choice.question_choice_title)+" ")])])}),1)],1):_vm._e(),(question.question_type == 'text')?_c('div',{},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(question.question_answers),expression:"question.question_answers"}],staticClass:"form-control my-3 ml-4",staticStyle:{"width":"-webkit-fill-available"},attrs:{"id":("question_text_" + (question.id)),"rows":"3","placeholder":"ตอบ"},domProps:{"value":(question.question_answers)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(question, "question_answers", $event.target.value)}}})]):_vm._e(),(question.question_type == 'rating')?_c('div',{staticClass:"mb-3"},[_c('vue-slide-bar',{attrs:{"data":_vm.slider.data,"range":_vm.slider.range,"labelStyles":{ color: '#4a4a4a', backgroundColor: '#4a4a4a' },"processStyle":{
              backgroundColor: _vm.genColors(question.question_answers),
            },"tooltipStyles":{
              backgroundColor: _vm.genColors(question.question_answers),
              borderColor: _vm.genColors(question.question_answers),
            }},model:{value:(question.question_answers),callback:function ($$v) {_vm.$set(question, "question_answers", $$v)},expression:"question.question_answers"}})],1):_vm._e()])])}),0):_vm._e(),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-lg-10 d-flex flex-row justify-content-end"},[_c('b-button',{staticClass:"ml-3",attrs:{"variant":"primary","disabled":_vm.isSubmiting},on:{"click":_vm.submitSurvey}},[(_vm.isSubmiting)?_c('b-spinner',{attrs:{"small":"","label":"submiting..."}}):_c('span',[_vm._v("ส่งคำตอบ")])],1)],1)])]):_c('div',{staticClass:"row justify-content-center"},[_vm._m(1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"spinner-border",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-8 my-5 text-center"},[_c('h5',[_vm._v("ขอขอบคุณอย่างยิ่ง ที่กรุณาร่วมมือตอบแบบสอบถามฉบับนี้")]),_c('h5',[_vm._v(" กองบัญชาการตำรวจปราบปรามยาเสพติดจะรวบรวมข้อมูลเพื่อปรับปรุงประสิทธิภาพการดำเนินโครงการ D.A.R.E. ประเทศไทยต่อไป ")])])}]

export { render, staticRenderFns }